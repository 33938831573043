function dunMaps (mapId) {
	this.mapId = 'reinos_map_' + mapId;
	this.markers = [];
	this.map = null;
	this.noResultLatLng = [52.4079379, 6.0612971];

	this.init = function(){
		var self = this;
		self.setMarkers();
		self.setMap();
		self.counter();
		self.restoreMapFromUrl();
		self.openFirstCategory();
	};

	this.setMarkers = function () {
		var self = this;
		self.markers =  REINOS_MAPS.markers[self.mapId].slice(0);

		//add popup events
		_.each(self.markers, function(marker){
			self.applyEvents(marker.marker);
		});
	};

	this.setMap = function(){
		var self = this;
		self.map = REINOS_MAPS._map_['#'+self.mapId];
	};

	/*Open a the root category when there is one root and not opened*/
	this.openFirstCategory = function(){
		var $roots = $('.categorie-link[data-root="true"]:not([data-collapse="true"])');

		//open the root if only one is found
		if($roots.length === 1) {
			$roots.attr("data-collapse", "true");
			$roots.find('.toggle-icon').remove();
		}
	};

	this.restoreMapFromUrl = function(){
		var self = this;
		var cat_ids = self.getParam('categories');
		var markerOpen = self.getParam('marker');

		//set the categories
		if(cat_ids !== '') {
			cat_ids = cat_ids.split(',');
			_.each(cat_ids, function(v){
				//check the categories that are selected
				$('.categorie-link[data-category="'+v+'"]:not(.data-collapse)').attr('data-select', 'true');
			});

			//check if we need to open the root if the child-roots are selected
			if($('.categorie-link[data-select="true"][data-child-root="true"]').length > 0) {
				$('.categorie-link[data-select="true"][data-child-root="true"]:first').parent().parent().parent().find('.categorie-link.data-collapse[data-root="true"]').attr('data-collapse', 'true');
			}

			//check if we need to open the root if the child-roots are selected
			if($('.categorie-link[data-select="true"][data-child-root="false"]').length > 0) {
				$('.categorie-link[data-select="true"][data-child-root="false"]:first').parent().parent().parent().parent().parent().find('.categorie-link.data-collapse[data-root="true"]').attr('data-collapse', 'true');
				$('.categorie-link[data-select="true"][data-child-root="false"]:first').parent().parent().parent().find('.categorie-link.data-collapse[data-root="false"]').attr('data-collapse', 'true');
			}

			//check if we need to open de root
			var $rootItem = $('.categorie-link.data-collapse[data-root="true"][data-category="'+cat_ids[0]+'"]');
			if(cat_ids.length > 0 && $rootItem.length > 0) {
				$rootItem.attr('data-collapse', 'true');
			}

			//check if we need to open de sub item
			var $subItem = $('.categorie-link.data-collapse[data-root="false"][data-category="'+cat_ids[0]+'"]');
			if(cat_ids.length > 0 && $subItem.length > 0) {
				$subItem.attr('data-collapse', 'true');
				$subItem.parent().parent().parent().find('.categorie-link.data-collapse[data-root="true"]').attr('data-collapse', 'true');
			}
		}

		//filter on this current selection
		self.filter(function(){
			//open a marker if needed
			if(markerOpen !== '') {
				var marker = self.searchMarker('entry_id_' + markerOpen);
				if(marker !== undefined && marker !== null && marker.marker !== undefined) {
					setTimeout(function(){
						marker.marker.openPopup();
					}, 1500);
				}
			}
		});
	};

	this.applyEvents = function(marker){
		var self = this;

		//add marker UUID to the segments
		marker.on('popupopen', function(e){
			var marker = self.searchMarker(e.target.markerUUID);

			//update position so the images is shown
			setTimeout(function(){
				marker.marker.getPopup().update();
			}, 200);

			var searchKey = e.target.extra.entry_id;
			self.setParam('marker', searchKey);
		});
		marker.on('popupclose', function(){
			self.setParam('marker', '');
		});
	};

	this.refreshMap = function(){
		var self = this;

		REINOS_MAPS.api('refresh', {mapID: self.mapId});
		setTimeout(function(){
			REINOS_MAPS.api('fitMap', {mapID: self.mapId, type: 'markers'});
		}, 100);
	};
	this.showMarker = function(marker, callback){
		var self = this;
		var options = _.extend(
			marker.userOptions,
			{
				mapID: self.mapId,
				fitBounds: false
			}
		);

		//add the marker to the map
		var newMarker = REINOS_MAPS.api('addMarker', options, callback);

		//apply new events for the marker
		self.applyEvents(newMarker);
	};
	this.getCatIds = function(){
		var self = this;
		var cat_ids =[];

		$('.categorie-link[data-select="true"]').each(function(){
			cat_ids.push($(this).data('category'));
		});

		//if there is no category found, check on the sub
		if(cat_ids.length === 0) {
			$('.categorie-link[data-collapse="true"][data-root="false"]').each(function(){
				cat_ids.push($(this).data('category'));
			});
		}

		//otherwise, if there is no category found, check on the root
		if(cat_ids.length === 0) {
			$('.categorie-link[data-collapse="true"][data-root="true"]').each(function(){
				cat_ids.push($(this).data('category'));
			});
		}

		return cat_ids;
	};

	this.searchMarker = function(marker_name){
		var self = this;
		var _marker = null;

		//loop over the markers
		_.each(REINOS_MAPS.markers[self.mapId], function(val, key) {
			//search the array
			if(val['keys'] !== undefined) {
				if(jQuery.inArray(marker_name, val['keys']) !== -1) {
					_marker = REINOS_MAPS.markers[self.mapId][key];
				}
			}
		});

		return _marker;
	};

	this.counter = function (counter) {
		var self = this;
		counter = counter || self.markers.length;

		//set total marker results
		$('.maps-results span').text(counter);
	};

	this.filter = function(callbackFunction){
		var self = this;
		var _markers = [];

		//check the callback
		callbackFunction = callbackFunction || function(){};

		//remove all layers from the map
		REINOS_MAPS.api('removeMarkers', {mapID: self.mapId});

		//get the catIDS to filter on
		var cat_ids = self.getCatIds();
		if(cat_ids.length > 0) {
			//loop over the markers and check what markers we can show based on the categories selected
			_.each(self.markers, function(marker){
				_.each(cat_ids, function(cat_id){
					if(jQuery.inArray(cat_id, marker.keys) !== -1) {
						_markers.push(marker.marker);
					}
				});
			});
		}

		//show the markers that are found
		if(_markers.length > 0) {
			_.each(_markers, function(marker, index){
				var callback = function(){};

				//last one in the loop? Refresh the map to center it to the bounds.
				if((index + 1) === _markers.length) {
					callback = function(){
						//refresh the map to center it
						self.refreshMap();

						//run the callback
						callbackFunction.apply();
					};
				}

				//show the marker
				self.showMarker(marker, callback);
			});
		}

		//no cat_ids selected, show all
		else if(cat_ids.length === 0) {
			//show all markers when there is no selection
			_.each(self.markers, function(marker, index){
				self.showMarker(marker.marker, function(){
					//refresh the map to center it
					self.refreshMap();

					//run the callback
					if((index + 1) === self.markers.length) {
						callbackFunction.apply();
					}
				});
			});
		}

		//no result
		else if(_markers.length === 0) {
			self.nothingFound();

			//run the callback
			callbackFunction.apply();
		}

		//also add the categories to the segment
		self.setParam('categories', cat_ids);

		//update counter
		self.counter(_markers.length);
	};

	this.nothingFound = function(){
		var self = this;
		self.map.map.flyTo(self.noResultLatLng, 10);
	};

	this.getParam = function(key) {
		var query =  document.location.search.replace('?', '').split('&');
		var value = '';
		_.each(query, function(val) {
			var find = val.split('=');
			if(find[0] === key) {
				value = find[1];
			}
		});

		return value;
	};

	this.setParam = function(key, value) {
		var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
			urlQueryString = document.location.search,
			newParam = key + '=' + value,
			params = '?' + newParam;

		// If the "search" string exists, then build params from it
		if (urlQueryString) {
			var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
			var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

			if( typeof value === 'undefined' || value == null || value === '' ) { // Remove param if value is empty
				params = urlQueryString.replace(removeRegex, "$1");
				params = params.replace( /[&;]$/, "" );

			} else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
				params = urlQueryString.replace(updateRegex, "$1" + newParam);

			} else { // Otherwise, add it to end of query string
				params = urlQueryString + '&' + newParam;
			}
		}

		// no parameter was set so we don't need the question mark
		params = params === '?' ? '' : params;

		window.history.replaceState({}, "", baseUrl + params);
	};
}