// (function($){
// 	if(typeof REINOS_MAPS !== 'undefined') {
// 		REINOS_MAPS.on('markers.ready', function () {
// 			var maps = new dunMaps($('#maps-wrapper').data('dunique-map-id'));
// 			maps.init();
//
// 			//toggle the filters on click
// 			$(".maps-container .maps-filters-toggle, .maps-container .maps-results__btn").on("click", function () {
// 				$(this).parents(".maps-container").attr("data-show-filters", $(this).parents(".maps-container").attr("data-show-filters") == "true" ? "false" : "true");
//
// 				//resize/refresh the map
// 				setTimeout(function () {
// 					maps.refreshMap();
// 				}, 500);
// 			});
//
// 			//open  the filters
// 			$(".maps-container .maps-filters-toggle").trigger('click');
//
// 			//only close filters on mobile devices
// 			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
// 				$(".maps-filters-overlay").on("click", function () {
// 					$(this).parents(".maps-container").attr("data-show-filters", $(this).parents(".maps-container").attr("data-show-filters") == "true" ? "false" : "false")
//
// 					//resize/refresh the map
// 					setTimeout(function () {
// 						maps.refreshMap();
// 					}, 500);
// 				});
// 			}
//
// 			//open a submenu (ROOT)
// 			$(".maps-container .data-collapse[data-root='true']").on("click", function () {
// 				//first remove all checked
// 				$('.categorie-link[data-select="true"]').each(function () {
// 					$(this).attr('data-select', 'false');
// 				});
//
// 				//hide the current active and show the new active one (both ROOT and SUB)
// 				$(".maps-container .data-collapse").attr("data-collapse", "false");
//
// 				$(this).attr("data-collapse", $(this).attr("data-collapse") == "true" ? "false" : "true");
//
// 				//filter the map with the active selected categories
// 				maps.filter();
// 			});
//
// 			//open a submenu (SUB)
// 			//todo duplicate code, make it DRY
// 			$(".maps-container .data-collapse[data-root='false']").on("click", function () {
// 				//first remove all checked
// 				$('.categorie-link[data-select="true"]').each(function () {
// 					$(this).attr('data-select', 'false');
// 				});
//
// 				//hide the current active and show the new active one (only SUB)
// 				$(".maps-container .data-collapse[data-root='false']").attr("data-collapse", "false");
//
// 				$(this).attr("data-collapse", $(this).attr("data-collapse") == "true" ? "false" : "true");
//
// 				//filter the map with the active selected categories
// 				maps.filter();
// 			});
//
// 			//click a checkbox
// 			$(".maps-container [data-select]").on("click", function () {
//
// 				//hide the sub sub items if needed
// 				if ($(this).data('child-root')) {
// 					$('.categorie-link[data-select="true"][data-child-root="false"]').each(function () {
// 						$(this).attr('data-select', 'false');
// 					});
// 					$(".maps-container .data-collapse[data-root='false']").attr("data-collapse", "false");
// 				}
//
// 				//set the state for the clicked one
// 				$(this).attr("data-select", $(this).attr("data-select") == "true" ? "false" : "true");
//
// 				//filter the map with the active selected categories
// 				maps.filter();
// 			});
// 		});
// 	}
// })(jQuery);
